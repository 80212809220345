<template>
  <div class="wrap">
    <div class="box-top">
      <el-input prefix-icon="el-icon-search" v-model="username" placeholder="请输入设置咨询师账号" clearable></el-input>
      <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" @click="add()">新增</el-button>

    </div>
    <common-table ref="commonTable" :dataSource="tableData" :columnsList="columns" :total="total"
                  @currentChange="currentChange" @sizeChange="sizeChange">
      <template slot="action" slot-scope="{record}">
        <el-popconfirm title="确定删除吗？" @confirm="confirm(record)">
          <el-button slot="reference" type="text" style="color: #b51c1b;">删除</el-button>
        </el-popconfirm>
      </template>
    </common-table>

  </div>
</template>

<script>
import {myMixins} from "../../mixins/myMixins.js";
import commonTable from "../../components/commonTable.vue";
import {isCounselor, counselorUser, delCounselor} from "@/api/counselor";


export default {
  name: "counselor",
  mixins: [myMixins],
  data() {
    return {
      formData: {},
      total: 0,
      pageSize: 10,
      pageNo: 1,
      tableData: [],
      username: '',
      columns: [
        {
          title: '账号',
          key: 'username',
          align: 'center'
        },
        {
          title: '用户昵称',
          key: 'nickname',
          align: 'center'
        },
        {
          title: '单位',
          key: 'company',
          align: 'center'
        },
        {
          title: '学历',
          key: 'education',
          align: 'center',
        },
        {
          title: '职业',
          key: 'work',
          align: 'center',
        },
        {
          title: '注册时间',
          key: 'createTime',
          align: 'center'
        },
        {
          title: '操作',
          align: 'center',
          key: 'action',
          scopedSlots: {
            columnSlot: 'action'
          }
        },
      ]
    }
  },
  components: {
    commonTable,
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      counselorUser(data).then(res => {
        console.log(res)
        if (res.success) {
          if (res.result.records.length == 0 && res.result.total != 0) {
            this.loadData();
          } else {
            this.tableData = res.result.records;
            this.total = res.result.total;
            // this.pageNo = res.result.current;
            // this.pageSize = res.result.size;
          }
          this.$nextTick(() => {
            this.$refs.commonTable.$refs.table.doLayout();
          })
        }
      })
    },
    add() {
      let data = {
        username: this.username
      }
      isCounselor(data).then(res => {
        console.log(res)
        if (res.success === false) {
          this.$message({type: 'error', message: res.message})
        } else if (res.success === true) {
          this.$message({type: 'success', message: res.message})
          this.loadData()
        }
      })
    },
    confirm(e) {
      // console.log(e);
      this.del(e);
    },
    del(e) {
      delCounselor({username: e.username}).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.loadData();
        } else {
          this.$message.error(res.message);
        }
      })
    },
  },
}
</script>

<style scoped>
.wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
}

.box-top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.box-top .el-button {
  border: none;
  border-radius: 25px;
  margin-right: 10px;
  background: #242424;
}

.box-top .el-input {
  width: 200px;
}
</style>
