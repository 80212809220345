import {getAction, postAction} from '../utils/request'

export function counselorUser(data) {//咨询师列表
    return getAction('/web/counselor/queryCounselor', data)
}

export function isCounselor(data) {//设置咨询师
    return getAction('/web/counselor/insertCounselor', data)
}

export function delCounselor(data) {//删除咨询师
    return getAction('/web/counselor/deleteCounselor', data)
}
